// fetch the list of recently approved classes.
export async function fetchClass(
  // setError, // function call to set an error message
  uniqueRef // the unique reference that the class catalog shall return at the maximum
) {
  let result = undefined; // default result if we can't fetch anything

  // exit early if someone hasn't provided a programme code
  if (!uniqueRef || uniqueRef.length === 0) {
    return result;
  }

  // prepare for loading the classes 
  const bffApiEndpoint = process.env.REACT_APP_API_BFF_BASE || 'BUGGER:REACT_APP_API_BFF_BASE';
  let apiUri = bffApiEndpoint + '/classes/' + uniqueRef;

  console.log('Calling ' + apiUri + ' ...');

  // call the API and get the classes
  let response = await fetch(apiUri, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json'
    },
    mode: 'cors' // TODO SO: ensure this is going to be a CORS call at some point
  }).then(
    response => {
      // if everything is okay, bypass the response code handling
      if (response.ok)
        return response;
      
      // now handle error edge cases
      switch(response.status) {
        case 404: // 404 means no class was found
          console.log("Class not found.");
          return undefined;
        case 409: // 409 means there is an issue with the data integrity in the API
          throw new Error('DATA INTEGRITY ERROR: ' + response.status + ': ' + response.statusText);
        default: // Other errors are not expected but UI should notify user
          throw new Error('UNEXPECTED ERROR: ' + response.status + ': ' + response.statusText);
      }
    }
  // it's important to handle errors here instead of a catch() block so that we don't swallow exceptions from actual bugs in components
  ).catch(err => {
    console.log(err.message);
  });
  
  if (!!response && response.ok) {
    const data = await response.json();
    result = {
      approvalTime: new Date(data.approvalTime),
      cancelTime: new Date(data.cancelTime),
      classCode: data.classCode,
      isApproved: data.isApproved,
      isCancelled: data.isCancelled,
      isEnrolling: data.isEnrolling,
      isWaitlisting: data.isWaitlisting,
      mainDeliverySiteId: data.mainDeliverySiteId,
      mainDeliverySiteLocationCode: data.mainDeliverySiteLocationCode,
      mainDeliverySiteDetails: data.mainDeliverySiteDetails,
      plannedTauiraNumber: data.plannedTauiraNumber,
      primaryLearningMethod: data.primaryLearningMethod,
      programmeCode: data.programmeCode,
      programmeName: data.programmeName,
      registryUnitName: data.registryUnitName,
      startYear: data.startYear,
      stopEnrolTime: new Date(data.stopEnrolTime),
      timetableBpEntries: data.timetableBpEntries === undefined || data.timetableBpEntries === null ? undefined : data.timetableBpEntries.map(entry => {
        return {
          day: entry.day,
          startTime: entry.startTime,
          endTime: entry.endTime
        }
      }),
      timetableBpEntriesTime: new Date(data.timetableBpEntriesTime),
      uniqueRef: data.uniqueRef,
      waitlistTime: new Date(data.waitlistTime)
    };

    console.log("Class loaded.");
  }
  
  // done
  return result;
}
